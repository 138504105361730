import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import http from "src/utils/http";
import Logo from "src/components/Logo";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller as Fc } from "react-hook-form";
import TreffasStyle from "src/utils/TreffasStyle";
import GoogleLogin from "./google";
import LinkedLogin from "./linkedin";

import SideBg from "../SideBg";
import Pendo from "src/utils/Pendo";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Users } from "src/context";
import { useSnackbarContext } from "src/context/SnackbarContext";

const Login = () => {
  const themes = useTheme();
  const navigate = useNavigate();
  const [isProcess, setIsProcess] = useState(false);
  const [isVerifyProcess, setIsVerifyProcess] = useState(false);

  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { mixpanel } = useContext(Users);
  const [showLink, setShowLink] = useState({
    enable: false,
    email: null,
  });
  const { showSnackbar } = useSnackbarContext();

  const baseURL = window.location.origin;

  const Schema = Yup.object({
    // organization: Yup.string().required("Organization is a required field"),
    email: Yup.string()
      .email(t("login.form.validation.email_invalid")) //"Enter valid email address.")
      .required(t("login.form.validation.email_required")), //"Email is a required field"),
    password: Yup.string().required(
      t("login.form.validation.pasword_required")
    ), //"Password is a required field"),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      // organization: "",
      email: "",
      password: "",
    },
  });

  const handleResentVerification = () => {
    let email = showLink.email;
    setIsVerifyProcess(true);
    http
      .post("/api/oauth/registration/sent-verification-email", {
        email: email,
        name: null,
        base_url: baseURL,
      })
      .then(({ data }) => {
        if (data.message === "success") {
          showSnackbar(
            t("registration.form.validation.verification-sent"),
            "success"
          );
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsVerifyProcess(false));
  };

  const onLogin = (values) => {
    setIsProcess(true);
    http
      .post("/api/oauth/login", {
        ...values,
        // organization: values.organization.trim().toLowerCase(),
      })
      .then(({ data }) => {
        if (data.message === "organization-not-found") {
          setError("organization", {
            type: "required",
            message: t("login.form.validation.organization_not_found"), // "Organization is not found.",
          });
        }

        if (data.message === "success") {
          // initialize pendo //
          Pendo.initialize(data.data);

          if (data.two_factor_enable) {
            navigate(
              `/two-factor/verification?token=${data.access_token}&id=${data?.data?.id}&organization=${data.data.organization.id}&mask=${data.mask}&type=${data.two_factor_type}`
            );
          } else {
            localStorage.setItem(`accessToken`, data.access_token);
            localStorage.setItem(`__loginType`, "coach");
            window.location.href = `/app/v2/calendar`;
          }
        }

        if (data.message === "account-invalid") {
          setError("submit", {
            type: "required",
            message: t("login.form.validation.credential_error"), //"Email or password is incorrect.",
          });
        }
        if (data.message === "account-unverified") {
          setShowLink({
            enable: true,
            email: values.email,
          });
          setError("submit", {
            type: "required",
            message: t("login.form.validation.account_unverified"), //"Account is not verified, follow the link sent in your email.",
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    mixpanel.track("Login Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            minHeight: "calc(100vh - 0px)",
          }}
        >
          <Box flexGrow={1} sx={{}}>
            {/* logo */}
            <Box
              sx={(themes) => ({
                height: "80px",
                alignItems: "center",
                [themes.breakpoints.up("md")]: {
                  alignItems: "center",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
                  pl: "99px",
                  display: "none",
                },
                [themes.breakpoints.down("md")]: {
                  justifyContent: "flex-start",
                  pl: "24px !important",

                  display: "flex",
                },
              })}
            >
              <Logo />
            </Box>

            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "flex",
                },
                justifyContent: "center",
                alignItems: "center",
                px: "24px",
              }}
            >
              <Box
                sx={{
                  mt: "30px",
                }}
                // border={1}
              >
                {/* login info */}
                <Box>
                  <Box mb={"4px"}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "32px",
                        lineHeight: "46px",
                        textAlign: "center",
                        letterSpacing: "-0.01em",
                        color: "#060A2D",
                      }}
                    >
                      {t("login.welcome")}
                      {/* Welcome back! */}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#838383",
                    }}
                  >
                    {/* Log in to your account to get back to your hub */}
                    {t("login.description.first")}
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#838383",
                    }}
                  >
                    {/* for scheduling meetings. */}
                    {t("login.description.second")}
                  </Typography>
                </Box>

                {/* button */}
                <Box
                  sx={{
                    mt: "33px",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px",
                    gap: "20px",
                  }}
                >
                  {/* google login btn */}
                  <GoogleLogin />

                  {/* linkedin login btn */}
                  <LinkedLogin />
                </Box>

                {/* divider */}
                <Box
                  sx={{
                    width: "100%",
                    mt: "34px",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <Box
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <Box>
                      <Divider>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            color: "#838383",
                          }}
                        >
                          {/* or Sign in with your email */}
                          {t("login.description.third")}
                        </Typography>
                      </Divider>
                    </Box>
                  </Box>
                </Box>

                {/* login form */}
                <Box
                  sx={{
                    width: "100%",
                    mt: "20px",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "460px",
                      m: "auto",
                    }}
                  >
                    <form onSubmit={handleSubmit(onLogin)}>
                      {/* <Box>
                        <FormControl fullWidth variant="standard">
                          <Typography sx={TreffasStyle.formLabel}>
                            Organization Name or Business Name
                          </Typography>

                          <Fc
                            name="organization"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="betty copper org"
                                type="text"
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.organization)}
                                helperText={errors.organization?.message}
                              />
                            )}
                          />
                        </FormControl>
                      </Box> */}

                      <Box sx={{ mt: "24px" }}>
                        <FormControl fullWidth variant="standard">
                          <Typography sx={TreffasStyle.formLabel}>
                            {/* Enter your email address */}
                            {t("login.form.email")}
                          </Typography>

                          <Fc
                            name="email"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder={t("login.form.placeholder.email")}
                                // placeholder="e.g. bettycooper@gmail.com"
                                type="text"
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>

                      <Box sx={{ mt: "24px" }}>
                        <FormControl fullWidth variant="standard">
                          <Typography sx={TreffasStyle.formLabel}>
                            {/* Enter password */}
                            {t("login.form.password")}
                          </Typography>

                          <Fc
                            name="password"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder={t(
                                  "login.form.placeholder.password"
                                )}
                                // placeholder="Enter your password"
                                type={showPassword ? "text" : "password"}
                                sx={TreffasStyle.formInput(themes)}
                                error={Boolean(errors.password)}
                                helperText={errors.password?.message}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                      >
                                        {!showPassword ? (
                                          <Visibility
                                            sx={{ fontSize: "18px" }}
                                          />
                                        ) : (
                                          <VisibilityOff
                                            sx={{ fontSize: "18px" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "12px",
                                lineHeight: "31px",
                                textAlign: "center",
                                color: "#0173FE",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("/reset/password")}
                            >
                              {/* Forgot Password? */}
                              {t("login.form.fotgot_password")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {errors.submit && (
                        <Box>
                          <FormHelperText
                            error
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {errors.submit.message}
                          </FormHelperText>
                        </Box>
                      )}

                      {showLink.enable && (
                        <Box sx={{ textAlign: "center" }}>
                          <Button
                            onClick={handleResentVerification}
                            variant={"text"}
                            size="small"
                            disabled={isVerifyProcess}
                            startIcon={
                              isVerifyProcess && (
                                <CircularProgress size={12} color="inherit" />
                              )
                            }
                          >
                            Resent verification
                          </Button>
                        </Box>
                      )}

                      <Box sx={{ mt: "16px" }}>
                        <Button
                          variant="contained"
                          fullWidth
                          type="submit"
                          sx={{
                            textTransform: "inherit",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "10px",
                            lineHeight: "31px",
                            fontWeight: 400,
                            height: "48px",
                          }}
                          disabled={isProcess}
                          startIcon={
                            isProcess && (
                              <CircularProgress color="inherit" size={15} />
                            )
                          }
                        >
                          {/* Log in */}
                          {t("login.form.login_btn")}
                        </Button>
                      </Box>

                      <Box sx={{ mt: "24px", pb: "24px" }}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            color: "#525F7F",
                          }}
                        >
                          {/* Don't have an account? */}
                          {t("login.form.signup_text")}
                          <Typography
                            variant="subtitle"
                            color="primary"
                            sx={{
                              cursor: "pointer",
                              marginLeft: 0.5,
                              marginRight: 0.5,
                              fontWeight: 700,
                            }}
                            onClick={() => navigate("/register")}
                          >
                            {/* Sign up */}
                            {t("login.form.signup_btn")}
                          </Typography>
                          {/* here */}
                          {t("login.form.signup_text_cont")}
                        </Typography>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* sidebg cover with box */}
          <Box>
            <SideBg />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
