import { Box } from "@mui/material";
import React from "react";
import Logo from "src/components/Logo";
import SideBg from "../SideBg";
import TreffasRegistration from "./treffas";

const Register = () => {
  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
          display: { lg: "flex" },
          flexDirection: { lg: "row-reverse" },
        }}
      >
        <Box flexGrow={1}>
          <Box
            sx={(themes) => ({
              height: "80px",
              alignItems: "center",
              [themes.breakpoints.up("md")]: {
                alignItems: "center",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
                pl: "99px",
                display: "none",
              },
              [themes.breakpoints.down("md")]: {
                justifyContent: "flex-start",
                pl: "24px !important",
                display: "flex",
              },
            })}
          >
            <Logo />
          </Box>

          <Box
            sx={{
              mt: {
                xs: "50px",
                md: "80px",
              },
            }}
          >
            <TreffasRegistration />
          </Box>
        </Box>

        {/* side logo cover with box */}
        <SideBg />
      </Box>
    </>
  );
};

export default Register;
