import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Page from "src/components/Page";
import http from "src/utils/http";
import { useLocation } from "react-router-dom";
import Logo from "src/components/Logo";
import ReactCodeInput from "react-code-input";
import { useTranslation } from "react-i18next";
import LB from "src/assets/images/plain-bg.png";
import { Users } from "src/context";
import { useSnackbarContext } from "src/context/SnackbarContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TwoFactor = () => {
  const searchParams = useQuery();
  const organization_id = searchParams.get("organization");
  const token = searchParams.get("token");
  const user_id = searchParams.get("id");
  const mask = searchParams.get("mask");
  const type = searchParams.get("type");
  const { t } = useTranslation();

  const [isProcess, setIsProcess] = useState(false);
  const { showSnackbar } = useSnackbarContext();
  var count = null;
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const resentBtnRef = React.useRef();
  const { mixpanel } = useContext(Users);

  const onVerify = () => {
    let error = [];

    if (code?.trim() === "" || code.length === 0) {
      error = "error-code";
      setError("Code is a required field.");
      showSnackbar(t("two_factor.alert.required.code"), "error");
    }

    if (error.length > 0) {
      console.log("form has an error:", error);
      return;
    }

    setIsProcess(true);
    http
      .post(
        `/api/user-account/two-factor/code/verification`,
        {
          code,
          organization_id,
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        if (data.message === "code-invalid") {
          showSnackbar(t("two_factor.alert.incorect.code"), "error");
          setError("Incorrect verification code.");
          return;
        }

        if (data.message === "code-verified") {
          localStorage.setItem(`accessToken`, token);
          window.location.href = `/app/v2/calendar`;
          return;
        }

        showSnackbar(t("two_factor.alert.invalid.code"), "error");
        setError("Invalid verification code.");
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  const onResent = () => {
    resentBtnRef.current.disabled = true;
    resentBtnRef.current.className = `${resentBtnRef.current.className} Mui-disabled`;
    http
      .post(
        `/api/user-account/two-factor/code/verification-resent`,
        {
          organization_id,
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        if (data.message === "success") {
          showSnackbar("Two factor code successfully sent.", "success");
          startCountDown();
          return;
        }
      })
      .catch((err) => console.log(err.message));
  };

  const startCountDown = () => {
    var minutes = 0;
    var seconds = 59;

    resentBtnRef.current.innerText = `Resend(${seconds})`;
    resentBtnRef.current.disabled = true;
    resentBtnRef.current.className = `${resentBtnRef.current.className} Mui-disabled`;

    count = setInterval(function () {
      if (parseInt(minutes) < 0 || parseInt(seconds) <= 0) {
        resentBtnRef.current.innerText = `Resend`;
        clearInterval(count);
        resentBtnRef.current.disabled = false;
        resentBtnRef.current.className = `${resentBtnRef.current.className.replace(
          "Mui-disabled",
          ""
        )}`;
      } else {
        resentBtnRef.current.innerText = `Resend(${seconds})`;
        seconds--;
        if (seconds < 10) seconds = "0" + seconds;
      }
    }, 1000);
  };

  React.useEffect(() => {
    mixpanel.track("Two Factor Confirmation Page", {
      location: window.location.href,
    });

    return () => {
      if (count) {
        clearInterval(count);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <Page title={t("two_factor.page")}>
      <Box
        sx={{
          backgroundImage: `url(${LB})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#cccccc",
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            minHeight: "100vh",
            background: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(300px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "640px",
              maxWidth: "640px",
              height: "659px",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    mt: "72px",
                    mb: "74px",
                    textAlign: "center",
                  }}
                >
                  <Logo />
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "32px",
                      lineHeight: "46px",
                      color: "#060A2D",
                      textAlign: "center",
                      letterSpacing: "-0.01em",
                    }}
                  >
                    {t("two_factor.page")}
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#838383",
                      textAlign: "center",
                    }}
                  >
                    {t("two_factor.label.secure_account")} <br />{" "}
                    {type === "email"
                      ? t("two_factor.label.thank_you.email")
                      : t("two_factor.label.thank_you.sms")}
                    :
                    <Typography
                      variant="subtitle"
                      color="primary"
                      sx={{
                        marginLeft: 0.5,
                        marginRight: 0.5,
                        fontWeight: 500,
                      }}
                    >
                      {mask}
                    </Typography>
                  </Typography>
                </Box>

                <Box my="80px">
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "31px",
                      color: "#060A2D",
                      textAlign: "center",
                    }}
                  >
                    {t("two_factor.label.verification_code")}
                  </Typography>

                  <Box mt="6px" textAlign="center">
                    <ReactCodeInput
                      type="text"
                      fields={6}
                      inputStyle={{
                        height: "53px",
                        width: "53px",
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "120%",
                        color: "#060A2D",
                        border: error
                          ? "1px solid #E92C2C"
                          : "1px solid #A3ABBF",
                        marginRight: "10px",
                        borderRadius: "8px",
                        textAlign: "center",
                      }}
                      value={code}
                      onChange={(data) => {
                        setCode(data);
                        setError("");
                      }}
                      placeholder="0"
                    />
                  </Box>
                </Box>

                <Box textAlign={"center"}>
                  <Box mb={"10px"}>
                    <Button
                      fullWidth
                      variant={"contained"}
                      sx={{
                        fontWeight: 700,
                        fontSize: "14px",
                        lineHeight: "31px",
                        borderRadius: "12px",
                        width: "406px",
                        height: "48px",
                      }}
                      onClick={() => onVerify()}
                      disabled={isProcess}
                      startIcon={
                        isProcess && (
                          <CircularProgress size={15} color="inherit" />
                        )
                      }
                    >
                      {t("two_factor.label.verify")}{" "}
                      {type === "email"
                        ? t("two_factor.label.verify.email")
                        : t("two_factor.label.verify.sms")}
                    </Button>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "24px",
                        textAlign: "center",
                        color: "#525F7F",
                      }}
                    >
                      {t("two_factor.label.code_didnt_received")}{" "}
                      <Button
                        variant={"text"}
                        color="primary"
                        sx={{
                          cursor: "pointer",
                          marginLeft: 0.5,
                          marginRight: 0.5,
                          fontWeight: 700,
                        }}
                        ref={resentBtnRef}
                        onClick={() => onResent()}
                      >
                        {t("two_factor.button.resend")}{" "}
                        {type === "email"
                          ? t("two_factor.label.verify.email")
                          : t("two_factor.label.verify.sms")}
                      </Button>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default TwoFactor;
