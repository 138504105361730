import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

import http from "src/utils/http";
import Logo from "src/components/Logo";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller as Fc } from "react-hook-form";
import TreffasStyle from "src/utils/TreffasStyle";

import SideBg from "../SideBg";
import { useTranslation } from "react-i18next";
import { Users } from "src/context";
import { useLocation } from "react-router-dom";
import { useSnackbarContext } from "src/context/SnackbarContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VerifyCode = () => {
  const themes = useTheme();
  const [isProcess, setIsProcess] = useState(false);
  const searchParams = useQuery();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const { t } = useTranslation();
  const { mixpanel } = useContext(Users);
  const { showSnackbar } = useSnackbarContext();

  const Schema = Yup.object({
    code: Yup.string().required("Code is a required field."),
  }).required();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      // organization: "",
      code: "",
      email: email,
      token: token,
    },
  });

  const onLogin = (values) => {
    setIsProcess(true);
    http
      .post("/api/oauth/login/client-portal/verify", {
        ...values,
      })
      .then(({ data }) => {
        if (data.message === "email-invalid") {
          showSnackbar("Email address is invalid.", "error");
        }

        if (data.message === "code-expired") {
          showSnackbar("The code has already expired.", "error");
        }

        if (data.message === "code-invalid") {
          setError("code", {
            required: true,
            message: "The code is invalid.",
          });
        }

        if (data.message === "success") {
          localStorage.setItem(`accessToken`, data.accessToken);
          localStorage.setItem(`__loginType`, "client");

          window.location.href = `/client/appointments`;
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => setIsProcess(false));
  };

  React.useEffect(() => {
    mixpanel.track("Login Client Portal Verify Code Page", {
      location: window.location.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 0px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            minHeight: "calc(100vh - 0px)",
          }}
        >
          <Box flexGrow={1} sx={{}}>
            {/* logo */}
            <Box
              sx={(themes) => ({
                height: "80px",
                alignItems: "center",
                [themes.breakpoints.up("md")]: {
                  alignItems: "center",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.04)",
                  pl: "99px",
                  display: "none",
                },
                [themes.breakpoints.down("md")]: {
                  justifyContent: "flex-start",
                  pl: "24px !important",

                  display: "flex",
                },
              })}
            >
              <Logo />
            </Box>

            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "flex",
                },
                justifyContent: "center",
                alignItems: "center",
                px: "24px",
              }}
            >
              <Box
                sx={{
                  mt: "30px",
                }}
                // border={1}
              >
                {/* login info */}
                <Box>
                  <Box mb={"12px"}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "26px",
                        lineHeight: "26px",
                        textAlign: "center",
                        letterSpacing: "-0.26px",
                        fontFamily: "Mukta",
                      }}
                    >
                      Please verify your email address
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "15px",
                      textAlign: "center",
                      color: "#6E757C",
                    }}
                  >
                    For security purposes, we’ve sent a 6-character <br /> code
                    to {email}. Please check <br /> your email and enter it
                    below.
                  </Typography>
                </Box>

                {/* login form */}
                <Box
                  sx={{
                    width: "359px",
                    mt: "32px",
                  }}
                >
                  <form onSubmit={handleSubmit(onLogin)}>
                    <Box>
                      <FormControl fullWidth variant="standard">
                        <Typography sx={TreffasStyle.formLabel}>
                          6-character code
                        </Typography>

                        <Fc
                          name="code"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder={"Enter a code here..."}
                              type="text"
                              sx={TreffasStyle.formInput(themes)}
                              error={Boolean(errors.code)}
                              helperText={errors.code?.message}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>

                    {errors.submit && (
                      <Box>
                        <FormHelperText
                          error
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {errors.submit.message}
                        </FormHelperText>
                      </Box>
                    )}

                    <Box sx={{ mt: "16px" }}>
                      <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{
                          borderRadius: "10px",
                          p: "12px 24px",
                        }}
                        disabled={isProcess}
                        startIcon={
                          isProcess && (
                            <CircularProgress color="inherit" size={15} />
                          )
                        }
                      >
                        Continue
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <SideBg />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyCode;
